<template>
    <v-app v-if="!$store.state.isLoading" :class="{'loading': $store.state.isLoading}">
      <template v-if="!$store.state.isLoading">
        <router-view :key="$route.fullPath"/>
        <div v-for="(item, index) in snackbars" :key="index">
          <v-snackbar
              v-model="item.active"
              :multi-line="item.multiLine"
              :timeout="item.timeout"
              absolute
              bottom
              right
              :color="item.color"
          >
            {{ item.text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="red"
                  text
                  v-bind="attrs"
                  @click="item.active = false"
              >
                <v-icon
                    dark
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </template>

    <v-progress-circular
        indeterminate
        color="primary"
        class="app-preloader"
        v-if="$store.state.isLoading"
    ></v-progress-circular>
  </v-app>

</template>

<script>

  export default {
    name: 'App',

    components: {},

    data: () => ({
      snackbars: []
    }),
    methods: {
      notify(options) {
        this.snackbars.push({
          active: true,
          text: options.text ? options.text : 'Something wrong',
          color: options.color ? options.color : 'success',
          timeout: options.timeout ? options.timeout : 5000,
          multiLine: options.multiLine ? options.multiLine : true
        })
      }
    },
    mounted() {
      this.$root.notify = this.notify.bind(this);
    },
  };
</script>
