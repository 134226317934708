import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
// axios
import axios from "./axios.js"
import '@/assets/css/main.css'
Vue.prototype.$http = axios
Vue.prototype.$axios = axios;
import 'vue-select/dist/vue-select.css';

const start = () => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
};

if (window.location.pathname !== "/connect/google" && localStorage.getItem("jwt") !== null) {
  axios.get("/users/me").then(res => {
    store.commit("setState", {user: res.data});
  }).catch(() => {
    localStorage.removeItem("jwt");
    delete axios.defaults.headers["Authorization"];
  }).finally(() => {
    setTimeout(() => {
      store.commit("setState", {isLoading: false});
      start();
    }, 300)
  });
} else {
  store.commit("setState", {isLoading: false});
  start();
}