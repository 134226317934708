// axios
import axios from 'axios'
import apiUrl from "./apiUrl";
let options = {
    baseURL: apiUrl
};
let jwt = localStorage.getItem("jwt");
if (jwt) {
    options.headers = {['Authorization'] : "Bearer " + jwt};
}

const instance = axios.create(options);
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
export default instance
