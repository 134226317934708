import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "./../store/index.js"

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  localStorage.setItem('loginRedirect', to.fullPath)
  if (store.state.user._id) {
    next();
  } else {
    next('/login');
  }
};

const routes = [
  {
    path: '',
    component: () => import('./../layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component:  () => import('../views/Home.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/categories',
        name: 'Categories',
        component:  () => import('../views/categories/Categories.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/categories/:id',
        name: 'Category',
        component:  () => import('../views/categories/Category.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/tests',
        name: 'Tests',
        component:  () => import('../views/tests/Tests.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/tests/:id',
        name: 'Test',
        component:  () => import('../views/tests/Test.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/tests/:id/config',
        name: 'TestConfig',
        component:  () => import('../views/tests/TestConfig.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/questions',
        name: 'Questions',
        component:  () => import('../views/questions/Questions.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/questions/:id',
        name: 'Question',
        component:  () => import('../views/questions/Question.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/answers',
        name: 'Answers',
        component:  () => import('../views/answers/Answers.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/answers/:id',
        name: 'Answer',
        component:  () => import('../views/answers/Answer.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/results',
        name: 'Results',
        component:  () => import('../views/results/Results.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/results/:id',
        name: 'Result',
        component:  () => import('../views/results/Result.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/test-passeds',
        name: 'TestPassed',
        component:  () => import('../views/tests-passed/TestsPassed.vue'),
        beforeEnter: ifAuthenticated
      },
    ]
  },
  {
    path: '',
    component: () => import('./../layouts/FullPage.vue'),
    children: [
      {
        path: '/login',
        name: 'page-login',
        component: () => import('./../views/pages/Login.vue')
      },
      {
        path: '/error-404',
        name: 'page-error-404',
        component: () => import('./../views/pages/Error404.vue')
      },
    ]
  },
  {
    path: '*',
    redirect: '/error-404'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
