import Vue from 'vue'
import Vuex from 'vuex'
import apiUrl from "../apiUrl";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appUrl: apiUrl,
    filesUrl: 'upload',
    user: {role: {name: "guest"}},
    isLoading: true
  },
  mutations: {
    setState(state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      })
    },
    logout(state) {
      state.user = {role: {name: "guest"}};
    }
  },
  actions: {
  },
  modules: {
  }
})
